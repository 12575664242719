<template>
  <div class="come">
    <button class="back" onclick="location.href='/'">ログアウト</button>
  </div>
  <!-- <div>
    社員番号:{{ state.employeeNumber }}
  </div> -->
  <div class="Attendance">

    <h2>勤怠一覧</h2>
    <table>
      <thead class="sample">
        <tr>
          <th>日付</th>
          <th>出社時間</th>
          <th>退社時間</th>
          <th>休憩開始</th>
          <th>休憩終了</th>
          <th>連絡事項</th>
        </tr>
      </thead>
      <tbody class="days">
        <tr v-for="attendance in attendanceList" :key="attendance.id">
          <td>{{ attendance.workDay }}</td>
          <td>{{ attendance.workStart }}</td>
          <td>{{ attendance.workEnd }}</td>
          <td>{{ attendance.breakStart }}</td>
          <td>{{ attendance.breakEnd }}</td>
          <td>{{ attendance.contactInfo }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <div class="subl">
      <button class="ch" @click="downloadCSV">ダウンロード</button>
    </div>
    <div class="subr">
      <button class="ch" onclick="location.href='/AttendanceRecord/${this.employeeNumber}'">登録</button>
    </div>
  </div>
</template>
    
<style scoped>
.Attendance {
  margin-top: 30px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
}

.sample {
  margin-top: 20px;
  text-decoration: underline;
}

.days {
  margin-top: 5px;
}

.come {
  float: left;
  width: 5%;
  align-items: center;
  position: fixed;
}

.back {
  background-color: #007dff;
  color: #fff;
  text-align: center;
}

.ch {
  background-color: #007dff;
  color: #fff;
  text-align: center;
}

.subr {
  float: right;
  /* float: center; */
  width: 5%;
  align-items: center;
}

.subl {
  float: left;
  /* float: center; */
  width: 5%;
  align-items: center;
}
</style>
<script>
export default {
  data() {
    return {
      attendanceList: [], // 勤怠一覧の配列
      employeeNumber: ""
    };
  },
  mounted() {
    this.fetchAttendanceList(); // コンポーネントがマウントされたときに勤怠一覧を取得する
  },
  methods: {
    async fetchAttendanceList() {
      try {
        const employeeNumber = this.$store.getters.getEmployeeNumber;
        this.employeeNumber = employeeNumber; // 社員番号を代入
        const response = await this.$axios.get(`${process.env.VUE_APP_API_URL}attendances/employeeNumber/${employeeNumber}`);
        console.log(response);

        switch (response.data.code) {
          case 'S0001': {
            this.attendanceList = response.data.data; // バックエンドから取得したデータを配列に格納
            break
          }

          case 'ERR01': {
            this.msg = '登録失敗しました';
          }
        }


      } catch (error) {
        console.error(error);
        window.alert('勤怠一覧の取得に失敗しました');
      }
    },
    async downloadCSV() {
      try {
        // CSVデータをダウンロードするためのHTTP GETリクエストを送信
        const response = await this.$axios.get( `${process.env.VUE_APP_API_URL}download-csv/${this.employeeNumber}`, {
          responseType: 'blob' // レスポンスをBlob形式で受け取る
        });
        console.log(response);
        // レスポンスからBlobオブジェクトを作成
        const blob = new Blob([response.data], { type: 'text/csv' });

        // Blobオブジェクトからダウンロード用のURLを生成
        const url = window.URL.createObjectURL(blob);

        // ダウンロードリンクを作成し、ファイル名を設定
        const a = document.createElement('a');
        a.href = url;
        a.download = `attendance_${this.employeeNumber}.csv`;

        // ダウンロードリンクをクリックしてファイルをダウンロード
        a.click();

        // 使用したURLを解放
        window.URL.revokeObjectURL(url);
      } catch (error) {

        //エラーハンドリング：エラーメッセージを出力し、アラートを表示
        console.error(error);
        window.alert('CSVファイルのダウンロードに失敗しました');
      }
    }
  }
};

</script>
