<template>
  <div class="register-container">
    <h2>アカウント登録</h2>
    <div>
      <form @submit.prevent="submitForm" class="register-form">
        <div class="form-item">
          <label for="name"></label>
          <input type="text" class="name" id="name" v-model="name" maxlength="20" placeholder="氏名" required>
        </div>

        <div class="form-item">
          <label for="employee-number"></label>
          <input type="tel" class="employeeNumber" id="employee-number" v-model="employee_number" maxlength="4"
            placeholder="社員番号" required>
        </div>

        <div class="form-item">
          <label for="password"></label>
          <input type="password" class="password" id="password" v-model="password" maxlength="25" placeholder="パスワード"
            required>
        </div>

        <div class="form-item">
          <label for="checkPass"></label>
          <input type="password" class="checkPass" id="confirm-password" v-model="checkPass" maxlength="25"
            placeholder="パスワード確認" required>
        </div>

        <p>
          アカウントをお持っている方は<router-link to="/" class="changeBtn">Login</router-link>
        </p>
        <p>
          {{ msg }}
        </p>

        <div class="form-item">
          <button class="btn" @click="sendData">登録</button>
        </div>
      </form>
    </div>
  </div>
</template>

<!-- css -->
<style scoped>
.register-container {
  margin-top: 100px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
}

.register-form {
  width: 55%;
  padding: 0.5rem;
  margin: auto;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn {
  background-color: #007dff;
  color: #fff;
  text-align: center;
  width: 50%;
  margin: auto;
  align-items: center;
}

.form-item {
  width: 70%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin: auto;
}

.password {
  margin-top: 100px;
  width: 70%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin: auto;
}

.checkPass {
  margin-top: 100px;
  width: 70%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin: auto;
}

.name {
  margin-top: 100px;
  width: 70%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin: auto;
}

.employeeNumber {
  margin-top: 100px;
  width: 70%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin: auto;
}
</style>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      name: '',
      password: '',
      employee_number: '',
      msg: '',
    };
  },
  methods: {
    async sendData() {
      if (!this.name || !this.password || !this.employee_number) {
        window.alert('すべて入力してください');
        return;
      }
      if (this.password !== this.checkPass) {
        window.alert('パスワードが一致しません');
        return;
      }

      const apiURL = `${process.env.VUE_APP_API_URL}employee`;
      const postData = {
        name: this.name,
        password: this.password,
        employeeNumber: this.employee_number
      };

      try {
        const response = await axios.post(apiURL, postData);
        console.log(response.data);
        //画面遷移
        //console.log(response.code);
        //onsole.log(response.status);
        switch (response.data.code) {
          case 'S0001': {
            const router = this.$router;
            router.push('/');
            break
          }

          case 'ERR01': {
            this.msg = '登録失敗しました';
          }
        }
      } catch (error) {
        if (error.response && error.response.data) {
          window.alert(error.response.data.message);
        } else {
          window.alert('エラーが発生しました');
        }
      }
    },
  },
};

</script>
