<template>
  <div class="WorkHours">
    <h2>勤務時間登録</h2>
    <div class="form">
      <span>日付：</span>
      <input type="date" v-model="workDay" placeholder="" required>
    </div>
    <div class="form">
      <span>出社時間：</span>
      <input type="time" v-model="workStart" placeholder="" required>
    </div>
    <div class="form">
      <span>退社時間：</span>
      <input type="time" v-model="workEnd" placeholder="" required>
    </div>
    <div class="form">
      <span>休憩開始：</span>
      <input type="time" v-model="breakStart" placeholder="" required>
    </div>
    <div class="form">
      <span>休憩終了：</span>
      <input type="time" v-model="breakEnd" placeholder="">
    </div>
    <div class="form">
      <span>連絡事項：</span>
      <input id="type" type="text" list="typelist" v-model="contactInfo" placeholder="選択して下さい">
      <datalist id="typelist">
        <option>有休</option>
        <option>半休</option>
        <option>私事</option>
        <option>その他</option>
      </datalist>
    </div>
    <div class="btn">
      <a href='/AttendanceList/${this.employeeNumber}'><button>戻る</button></a>
      {{ msg }}
      <button v-on:click="post">登録</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: "",
      workDay: "",
      workStart: "",
      workEnd: "",
      breakStart: "",
      breakEnd: "",
      contactInfo: "",
      employeeNumber: ""
    };
  },
  // Vue.jsのコンポーネント内でデータを取得してAPIリクエストを行う
  methods: {
    async post() {
      const apiURL = `${process.env.VUE_APP_API_URL}attendance`;
      const attendanceData = {
        employeeNumber: this.$store.state.employeeNumber,
        workDay: this.workDay,
        workStart: this.workStart,
        workEnd: this.workEnd,
        breakStart: this.breakStart,
        breakEnd: this.breakEnd,
        contactInfo: this.contactInfo,
      };

      try {
        const result = await this.$axios.post(apiURL, attendanceData);
        // リクエスト成功時の処理
        console.log(result.data);

        switch (result.data.code) {
          case 'S0001': {
            window.alert('登録に成功しました')
            const router = this.$router;
            router.push(`/AttendanceList/${this.$store.state.employeeNumber}`);
            this.msg = "登録が成功しました";
            // レスポンスデータを表示など
            // 成功したら画面遷移などの処理を行う場合はここで実装
            break
          }

          case 'ERR01': {
            this.msg = '登録失敗しました';
          }
        }
      } catch (error) {
        // リクエスト失敗時の処理
        console.error(error);
        window.alert('エラーが発生しました。')
      }
    }
  }

};

</script>

<!-- css -->
<style scoped>
.WorkHours {
  margin-top: 50px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;

}

h2 {
  font-size: 30px;
  line-height: 80pt;
}

.form input {
  width: 200px;
  vertical-align: top;
}

span {
  display: inline-block;
  width: 150px;
  text-align: justify;
  height: 50px;
  overflow: hidden;
}

span:after {
  content: '';
  display: inline-block;
  width: 100%;
}

.btn {
  text-align: center;
}

/*.button{
    position: relative;
    height: 33px;
    width: 150px;
    background-color: #007dff;
    border-radius: 10px;
    margin-top: 38px;
    box-shadow: none;
    color: white;
    margin: 100px;
}
*/
</style>