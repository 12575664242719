import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate' //インストール 


//認証状態を管理するための定義
export default createStore({
  state: {
    isLogin: false, //ログイン状態
    userId: '', //トークン
    employeeNumber: ''
  },
  mutations: {
    auth(state, user){
      state.isLogin = true;
      state.userId = user;
    },
    setEmployeeNumber(state, employeeNumber) {
      state.employeeNumber = employeeNumber;
    }
  },
  actions: {
    fetch(context, payload) {
      context.commit('auth', payload.user);
      context.commit('setEmployeeNumber', payload.employeeNumber);
    },
//actions: {
  setAuthToken: function(commit, value) {
    commit('setAuthToken', value)
  },
  setIsLogin: function(commit, value) {
    commit('setIsLogin', value)
  }
},
getters:{
  getEmployeeNumber(state) {
    return state.employeeNumber;
  },
  getAuthToken(state){
    return state.authToken
  },
  getIsLogin(state){
    return state.isLogin
  }
},

plugins: [createPersistedState(
  { 
    storage: window.sessionStorage
  }
)]

})


