import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import vuetify from '../node_modules/vuetify';
import 'bootstrap/dist/css/bootstrap.min.css';
//import urlConfig from './url.config';

const app = createApp(App)


// axiosを使用できるように定義
app.config.globalProperties.$axios = axios.create({
    baseURL: process.env.VUE_APP_AXIOS_BASE_URL
})

app.use(store)
app.use(router)
app.use(vuetify)
app.mount('#app')
