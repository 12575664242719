import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue'
import AttendanceList from '../components/AttendanceList.vue'
import AttendanceRecord from '../components/AttendanceRecord.vue'
import Register from '../components/Register.vue'
import Store from '../store/index.js'


//VueRouterを使って画面遷移の定義をする

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes:[
    {
      path: '/AttendanceList/:employeeNumber',
      name: 'list',
      component: AttendanceList,
      meta: {
        requiresAuth: true //認証済みのときのみ閲覧可能になるように定義
      }
    },
    {
      path: '/AttendanceRecord/:employeeNumber',
      name: 'record',
      component: AttendanceRecord,
      meta: {
        requiresAuth: true //認証済みのときのみ閲覧可能になるように定義
      }
    },
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/Register',
      name: 'register',
      component: Register
    }
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Store.state.isLogin) {
      //認証されていない時、認証画面へ遷移
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
