<template>
    <div class="login">
        <h2>勤怠システム</h2>
       <!-- <div class="inputItemDiv" style="color:red">{{ message }}</div> -->
        <div>
          <div class="container" style="width: 500px;">
            <div class="row align-items-center tel">
                <input type="tel" class="id" v-model="id" maxlength="4" placeholder="社員番号" required>
            </div>
            <div class="row align-items-center text" >
                <input type="password"  class="password" v-model="password" maxlength="25" placeholder="パスワード" 
                prepend-icon="mdi-lock" v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                required>
            </div>
            <h1>{{ baseURL }}</h1>
            {{ msg }}
            <div class="row align-items-center button">
            <button class="btn btn-info Login"  v-on:click="post">
                    ログイン
            </button>
            </div>
            <div class="coment">
            <p class="register">
                アカウントをお持ちでない方は<a href="/Register">SignUp</a>
             </p>
            </div>
          </div>
        </div>
       
    </div>
</template>

<style scoped>
.login {
    margin-top: 100px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    text-align: center;
}
.id{
    margin-top: 100px;
    width: 70%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    margin: auto;
}
.password{
    width: 70%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    margin: auto;
}
.tel{
    padding: 10px;
}
.text{
    padding: 10px;
}
.button{
    padding: 20px;
    margin: auto;
}
.btn{
    background-color: #007dff;
    color: #fff;
    text-align: center;
    width: 50%;
    margin: auto;
    align-items: center;
}
.coment{
    padding: 10px;
}
</style>

<script>
export default{
    name: 'Login',
    data() {
        return{
            showPassword : false,
            msg : '社員番号とパスワードを入力してください。',
            id : '',
            password : '', 
            baseURL:process.env.VUE_APP_BASE_URL
        }
    },
    methods: {
        async post() {
            const data = { 
                employeeNumber : this.id, 
                password : this.password 
            };

            try {
               const resultLocal = await this.$axios.post(`${process.env.VUE_APP_API_URL}login`, data ); //ここでバックエンド側にポスト
               console.log(resultLocal);
                
               var datalocal = resultLocal.data;
                switch(datalocal.code){
                    case 'S0001':
                    this.$store.dispatch('fetch', { user: this.authId, employeeNumber: this.id });
                    //this.$store.dispatch('saveToken', resultLocal.headers['x-auth-token'])
                    this.$router.push('/AttendanceList/${this.id}');
                    break;
                    case 'ERR01':
                    this.msg ='社員番号又はパスワードが間違っています。'
                   // default:    
                }
            } catch (error) {
                console.error(error);
                window.alert('エラーが発生しました');
             }
        }
    }
};

</script>